/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { MenuContextProvider } from "../../contexts/MenuContext";
import PrimaryNav from "../PrimaryNav/PrimaryNav";
import SecondaryNav from "../SecondaryNav/SecondaryNav";

const GlobalNav = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Listen for this event in your host app for a notification when global nav is ready to receive data
      let event = new Event("globalNavIsRendered");
      function dispatch() {
        window.document.dispatchEvent(event);
      }
      dispatch();
    }
  }, []);
  return (
    <MenuContextProvider>
      <PrimaryNav></PrimaryNav>
      <SecondaryNav></SecondaryNav>
    </MenuContextProvider>
  );
};

export default GlobalNav;
