/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { menuData } from "../../data";
import NavLink from "../NavLink/NavLink";
import * as Styled from "./styled";
import settings from "../../settings";

const PrimaryNav = (props) => {
  const language = process.env.REACT_APP_LANGUAGE ?? "en";
  const [open, setOpen] = useState(false);
  const context = useContext(MenuContext);
  const domain = settings.d7_domain;

  const element = useRef(null);
  const handleClickOutside = useCallback(
    (e) => {
      const node = element.current;
      if (
        node &&
        node.contains(e.target) &&
        e.target.hasAttribute("href") === false
      ) {
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open, handleClickOutside]);

  return (
    <Styled.NavOuter data-position="primary">
      <Styled.NavContainer>
        <Styled.NavLeft>
          <NavLink href={context.home}>
            <Styled.Logo role="img" aria-label="Times Higher Education logo" />
          </NavLink>
        </Styled.NavLeft>
        <Styled.NavRight parent={context.data.parent}>
          <Styled.NavList open={open}>
            {menuData[language].map((data, key) => {
              return (
                <Styled.NavItem key={key}>
                  <NavLink href={data.href} dataText={data.label}>
                    {data.label}
                  </NavLink>
                </Styled.NavItem>
              );
            })}
          </Styled.NavList>
        </Styled.NavRight>
        <Styled.NavSpacer parent={context.data.parent}>
          {context.data.parent === "noSecondary" && (
            <NavLink href={`${domain}/search`} className={"search-icon"} />
          )}
          <Styled.MenuButton
            onClick={() => setOpen(!open)}
            open={open}
            parent={context.data.parent}
          >
            <span role="img" aria-label="Menu"></span>
          </Styled.MenuButton>
        </Styled.NavSpacer>
      </Styled.NavContainer>
    </Styled.NavOuter>
  );
};

export default PrimaryNav;
