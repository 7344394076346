import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import * as Styled from "./styled";

// TODO: Memoise things?
const ScrollControl = (props) => {
  const [activeArrows, setActiveArrows] = useState({
    left: false,
    right: false,
  });
  const [scrollControlState, setScrollControlState] = useState({
    status: false,
    scrollPos: 0,
    maxScroll: 0,
    visibleWidth: 0,
  });

  const browserSize = useWindowSize();
  const scrollControl = useRef(null);
  const scrollWrapper = useRef(null);
  const scrollContainer = useRef(null);

  // TODO: Throttle this: https://www.npmjs.com/package/@react-hook/throttle
  const updateVerticalScroll = () => {
    setScrollControlState((prevState) => ({
      ...prevState,
      scrollPos: scrollWrapper.current.scrollLeft,
    }));
  };

  // Scroll to position
  const setVerticalScroll = (val) => {
    scrollWrapper.current.scrollLeft = val;
  };

  // TODO: Refactor and define how far to scroll.
  const scrollAction = (dirRight, e) => {
    e.preventDefault();
    const cur = scrollControlState.scrollPos;
    let scroll = 0;
    if (dirRight) {
      scroll = cur + scrollControlState.visibleWidth / 2;
    } else {
      scroll = cur - scrollControlState.visibleWidth / 2;
    }

    setVerticalScroll(scroll);
  };

  // On component mount, listen for scroll of menu element.
  useEffect(() => {
    const node = scrollWrapper.current;
    if (node) {
      // Update scroll position value whenever menu is scrolled
      node.addEventListener("scroll", updateVerticalScroll);

      return () => {
        node.removeEventListener("scroll", updateVerticalScroll);
      };
    }
  }, []);

  // On component mount and activeItem update
  useEffect(() => {
    const node = scrollWrapper.current;
    if (node) {
      const menuItems = node.children[0].children[0].children; // There must be a way to find these items in React?!
      // Scroll to active item
      if (menuItems) {
        for (const item of menuItems) {
          if (props.activeItem === item.dataset.menuId) {
            const scrollTo = item.offsetLeft - 60; // Account for left arrow size (60px)
            setVerticalScroll(scrollTo);
          }
        }
      }

      return () => {};
    }
  }, [props.activeItem]);

  // Fires on: Init, browser resize, menu horizontal scroll
  // TODO: Throttle this? https://www.npmjs.com/package/@react-hook/throttle
  useEffect(() => {
    const visibleWidth = scrollControl.current.scrollWidth;
    const actualWidth = scrollContainer.current.scrollWidth;
    const maxScroll = actualWidth - visibleWidth;
    const scrollPosRounded = Math.round(scrollControlState.scrollPos);

    // pass to state
    setScrollControlState((prevState) => ({
      ...prevState,
      maxScroll: maxScroll,
      visibleWidth: visibleWidth,
    }));

    // Which arrows to show when.
    if (maxScroll <= 0) {
      setScrollControlState((prevState) => ({
        ...prevState,
        status: false,
      }));
      return;
    } else {
      setScrollControlState((prevState) => ({
        ...prevState,
        status: true,
      }));
      if (scrollPosRounded > 0) {
        setActiveArrows((prevState) => ({ ...prevState, left: true }));
      } else {
        setActiveArrows((prevState) => ({ ...prevState, left: false }));
      }
      if (scrollPosRounded >= maxScroll) {
        setActiveArrows((prevState) => ({ ...prevState, right: false }));
      } else {
        setActiveArrows((prevState) => ({ ...prevState, right: true }));
      }
    }
  }, [browserSize, scrollControlState.scrollPos]);

  // TODO: Refactor ScrollButton props
  return (
    <Styled.ScrollControl ref={scrollControl}>
      {scrollControlState.status && (
        <Styled.ScrollButton
          onClick={(e) => scrollAction(false, e)}
          visible={activeArrows.left}
          direction={"left"}
        >
          <span role="img" aria-label="Scroll left"></span>
        </Styled.ScrollButton>
      )}
      <Styled.ScrollWrapper ref={scrollWrapper}>
        <div ref={scrollContainer}>{props.children}</div>
      </Styled.ScrollWrapper>
      {scrollControlState.status && (
        <Styled.ScrollButton
          onClick={(e) => scrollAction(true, e)}
          visible={activeArrows.right}
          direction={"right"}
        >
          <span role="img" aria-label="Scroll right"></span>
        </Styled.ScrollButton>
      )}
    </Styled.ScrollControl>
  );
};

export default ScrollControl;
