import GlobalNav from "../src/components/GlobalNav/GlobalNav";

// Can we basically remove this component altogether?
function Index() {
  return (
    <>
      <GlobalNav />
    </>
  );
}

export default Index;
