/** @jsxImportSource @emotion/react */
import { handleInternalLocation } from "./mzReferrer";

const NavLink = ({
  children,
  href,
  dataText,
  className,
  target,
  mzInfo,
  additionalMzProps,
}) => {
  const additionalMzWithDataPrefix = {};
  // need to add the data attribute to enable access
  if (additionalMzProps) {
    Object.keys(additionalMzProps).forEach((key) => {
      additionalMzWithDataPrefix[`data-${key}`] = additionalMzProps[key];
    });
  }
  const mzProps = !mzInfo && {
    "data-mz": true,
    "data-type": href,
    ...additionalMzWithDataPrefix,
  };
  const mzClickHandler = (e) => {
    if (!mzProps && target === "_blank") {
      // this could also be another type of event we want to fire on link click
      window._mz.emit(window._mz.events.EXIT_CLICK, mzInfo);
    } else {
      handleInternalLocation(e);
    }
  };
  return (
    <a
      href={href}
      {...mzProps}
      data-text={dataText}
      className={className}
      target={target}
      rel={target === "_blank" ? "noopener" : ""}
      {...(typeof window !== "undefined" && window._mz
        ? { onClick: mzClickHandler }
        : {})}
    >
      {children}
    </a>
  );
};

export default NavLink;
