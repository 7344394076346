import settings from "./settings";
const d7_domain = settings.d7_domain;
const academic_domain = settings.academic_domain;
const student_domain = settings.student_domain;
const campus_domain = settings.campus_domain;
const unijobs_domain = settings.unijobs_domain;

export const menuData = {
  en: [
    {
      label: "Home",
      href: `${academic_domain}/`,
    },
    {
      label: "News",
      href: `${d7_domain}/academic/news`,
    },
    {
      label: "Rankings",
      href: `${d7_domain}/world-university-rankings`,
    },
    {
      label: "Jobs",
      href: `${unijobs_domain}/unijobs/`,
    },
    {
      label: "Students",
      href: `${student_domain}/student`,
    },
    {
      label: "Events",
      href: `${d7_domain}/events`,
    },
    {
      label: "Resources",
      href: `${campus_domain}/campus`,
    },
    {
      label: "Solutions",
      href: `${d7_domain}/our-solutions`,
    },
  ],
  cn: [
    {
      label: "首页",
      href: `${academic_domain}/cn`,
    },
    {
      label: "新闻快报",
      href: `${d7_domain}/cn/academic/news`,
    },
    {
      label: "排名",
      href: `${d7_domain}/cn/world-university-rankings`,
    },
    {
      label: "人才招聘",
      href: `${unijobs_domain}/unijobs/`,
    },
    {
      label: "海外留学",
      href: `${student_domain}/student`,
    },
    {
      label: "活动",
      href: `${d7_domain}/cn/events`,
    },
    {
      label: "资源",
      href: `${campus_domain}/campus`,
    },
    {
      label: "服务",
      href: `${d7_domain}/cn/our-solutions`,
    },
  ],
};
