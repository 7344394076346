import styled from "@emotion/styled";

export const ScrollControl = styled.div`
  display: flex;
  position: relative;
`;

export const ScrollWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
`;

export const ScrollButton = styled.button`
  cursor: pointer;
  width: 60px;
  height: 45px;
  position: absolute;
  top: 0;
  border: none;
  margin: 0;
  padding: 0;
  background: white;
  font-weight: bold;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
  ${(props) =>
    props.direction === "left" &&
    `
      left: 0;
      background: linear-gradient(to right, white, white 70%, rgba(255,255,255,0.5) 100%);
      `}
  ${(props) =>
    props.direction === "right" &&
    `
      right: 0;
      background: linear-gradient(to left, white, white 70%, rgba(255,255,255,0.5) 100%);
      `}
  ${(props) =>
    props.visible &&
    `
      visibility: visible;
      opacity: 1;
      `}
  & > span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    ${(props) =>
      props.direction === "left" &&
      `
        background-position: left center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0iYmxhY2siIGQ9Im0tMC41IDhsNy41LTcuNSAxLjEgMS02LjUgNi41IDYuNSA2LjQtMS4xIDEuMXoiLz4KPC9zdmc+Cg==");
        `}
    ${(props) =>
      props.direction === "right" &&
      `
        background-position: right 16px center; // don't use ems here to try line up with the parent apps.
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbD0iYmxhY2siIGQ9Im04LjUgOGwtNy41IDcuNS0xLTEuMSA2LjQtNi40LTYuNC02LjQgMS0xLjF6Ii8+Cjwvc3ZnPgo=");
      `}
  }
`;
