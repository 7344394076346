// copied from https://github.com/THE-Engineering/mz/blob/df9faabd065cfbe76b1447f2d143ac54793177c0/src/auto-links.js#L16

/**  To be fired everytime we do a client side SPA type navigation.
 * It traverses the link elements ancestors to gather information to populate
 * a referrer object for mz
 */

export const handleInternalLocation = (e) => {
  const el = e.target;

  const { _utils: utils } = window._mz;

  /* Object to store data attribute on el and it's parents */
  const data = {};

  /* Traverse the DOM from el and collect all data attributes */
  utils.traverseDOM(el, (node) => {
    utils.forIn(node.dataset, (value, key) => {
      if (!data[key]) {
        data[key] = value;
      }
    });

    return node;
  });

  data.pagetype =
    (window.TES.pageMetadata && window.TES.pageMetadata.type) || "";

  delete data.mz;
  window._mz.setReferrerData(data);
};
