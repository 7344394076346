import React, { useEffect, useState } from "react";
import settings from "../settings";

const academic_domain = settings.academic_domain;
const MenuContext = React.createContext();

// let event = new CustomEvent('theGlobalNavContextReady', { detail: { parent:'news', active:'news:home', app:'academic-cms' } });
// function dispatch() {
//     window.document.dispatchEvent(event);
//     console.log('event dispatched');
// }

const MenuContextProvider = (props) => {
  // debug
  // const [state, setState] = useState({
  //   home: `${domain}/`,
  //   data: {
  //     parent: 'news',
  //     active: 'news:home',
  //     app: 'academic',
  //   },
  // });
  const defaultSecondary = process.env.REACT_APP_DEFAULT_SECONDARY ?? null;
  const [state, setState] = useState({
    home: `${academic_domain}/`,
    data: {
      parent: defaultSecondary,
      active: null,
      app: defaultSecondary,
    },
  });

  useEffect(() => {
    function updateContext(event) {
      if (event.type === "theGlobalNavContextReady") {
        setState((prevState) => ({
          ...prevState,
          data: event.detail,
        }));
      } else {
        console.log("failed");
      }
    }

    window.document.addEventListener("theGlobalNavContextReady", updateContext);
    return () =>
      window.removeEventListener("theGlobalNavContextReady", updateContext);
  }, []);

  return (
    <MenuContext.Provider value={state}>{props.children}</MenuContext.Provider>
  );
};

export { MenuContext, MenuContextProvider };
